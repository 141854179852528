import React from "react"
import _ from "lodash";
import { Link } from "gatsby";
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../style/normalize.css"
import "../style/all.scss"

const TopicIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const topics = data.allMarkdownRemark.distinct

  return (
    <Layout title={siteTitle}>
      <SEO title="Topics" />

      <article className="post-content page-template no-image">
        <header className="post-content-header">
            <h1 className="post-content-title">Topics</h1>
        </header>

        <div className="post-content-body">
          <div className="topic-container">
            {topics.map(topic => {
              return(
                  <Link
                  key={topic}
                  style={{ textDecoration: "none" }}
                  to={`/topics/${_.kebabCase(topic)}`}
                  >
                  <div className="topic-item">#{topic}</div>
                  </Link>
                )
            })}
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      distinct(field: frontmatter___topics)
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <TopicIndex props data={data} />
    )}
  />
)